import Vue from 'vue'
import Vuex from 'vuex'

import simulatorService from '@/services/simulator.service'


Vue.use(Vuex)

const DEFAULT_SIM = {
  location: null,
  surfaces: [],
  averageMonthlyConsumptionkWh: 350,
}

export default new Vuex.Store({
  state: {
    simulation: DEFAULT_SIM,
    results: null,
  },
  getters: {
    simulation(state) {
      return state.simulation
    },
    results(state) {
      return state.results
    },
  },
  mutations: {
    setLocation(state, location) {
      state.simulation.location = location
    },
    setSurfaces(state, payload) {
      state.simulation.surfaces = payload
    },
    setAverageMonthlyConsumptionkWh(state, payload) {
      state.simulation.averageMonthlyConsumptionkWh = payload
    },
    setReportJSON(state, payload) {
      state.results = payload
    },
  },
  actions: {
    async runSimulationReports({ getters, commit }) {
      const data = serializeData(getters)
      try {
        const response = await simulatorService.runSimulationReports(data)
        const output = await response.json()
        if (!response.ok) {
          commit('setReportJSON', { error: output })
        } else {
          commit('setReportJSON', output)
        }
      } catch {
        commit('setReportJSON', { error: 'no_response' })
      }
    },
  }
})

function serializeData(state) {
  const sim = state.simulation
  return {
    average_monthly_consumption_kwh: sim.averageMonthlyConsumptionkWh,
    latitude: sim.location ? sim.location.lat : null,
    longitude: sim.location ? sim.location.lng : null,
    planes: sim.surfaces,
  }
}
