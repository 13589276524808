export default {
  async fetchRequest(method, endpoint, data) {
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'Vary': 'Accept'
      },
    }
    if (data) {
      options.body = JSON.stringify(data)
    }

    const rawResponse = await fetch(endpoint, options)
    return rawResponse
  },
}
