import apiService from './api.service'

const BASE_URL = process.env.VUE_APP_SIMULATOR_URL

export default {
  runSimulationReports(data) {
    return apiService.fetchRequest('POST', `${BASE_URL}/simulate/2391cf5f-08cf-468b-801a-1ea221d1e6cb`, data)
  },
  runPing() {
    return apiService.fetchRequest('GET', `${BASE_URL}/ping/2391cf5f-08cf-468b-801a-1ea221d1e6cb`)
  }
}

